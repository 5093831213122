import { gql } from "@apollo/client";

export const GET_SERVER_ERRORS = gql`
   query {
      serverErrors @client {
         message
      }
   }
`;

export const GET_LOCAL_USER = gql`
   query {
      me @client {
         id
         role
         module {
            id
            name
            slug
         }
      }
   }
`;

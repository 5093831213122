import { gql } from "@apollo/client";

export const GET_WORKSPACE_OWNER = gql`
   query getWorkspaceOwner {
      me @client {
         id
         workspace {
            id
            owners {
               id
            }
         }
      }
      modules {
         id
         name
      }
   }
`;

export const GET_CURRENT_WORKSPACE_CLIENT = gql`
   query getWorkspaceOwner {
      me @client {
         id
         name
         workspace {
            id
            name
            modules {
               id
               name
            }
         }
      }
   }
`;

export const GET_PRINTER_HEADER = gql`
   query currentUser {
      me @client {
         id
         name
         profile {
            first_name
            last_name
            force_barcode_scanner
         }
         workspace {
            id
            name
            image {
               path
               filename
            }
         }
      }
   }
`;

export const PRINT_WORKSPACE_INSTALLATION = gql`
   query printInstallation {
      printInstallation {
         pdf
      }
   }
`;

export const GET_INIT_DATA = gql`
   query currentUser {
      me {
         id
         name
         profile {
            first_name
            last_name
            force_barcode_scanner
         }
         image {
            filename
            path
         }
         role
         workspace {
            id
            name
            lite_version
            partner {
               id
               name
               image {
                  path
                  filename
               }
            }

            owners {
               id
               name
            }
            image {
               path
               filename
            }
            user_modules_id
            user_modules {
               id
               name
               slug
               order
            }
            modules {
               id
               name
               slug
               lite_version_visible
               order
            }
         }
         module {
            id
            name
            slug
         }
         workspaces {
            id
            name
         }
      }
      modules {
         id
         name
         slug
         lite_version_visible
         order
      }
   }
`;

export const GET_CURRENT_USER = gql`
   query currentUser {
      me {
         id
         name
         role
         workspace {
            id
            name
            code_type
         }
         profile {
            first_name
            last_name
            force_barcode_scanner
         }
         image {
            filename
            path
         }
         email
      }
   }
`;

export const GET_WORKSPACES = gql`
   query getWorkspaces {
      me {
         id
         workspace {
            id
            name
         }
         workspaces(all: true) {
            id
            name
            created_at
            owners {
               id
               name
            }
            is_template
         }
      }
   }
`;

export const GET_WORKSPACE = gql`
   query getWorkspace($id: String!) {
      me @client {
         id
         name
         workspace {
            id
            name
         }
      }
      workspace(id: $id) {
         id
         name
         note
         api_key
         code_type
         warehouse_enabled
         dpi_order_enabled
         is_hidden
         lite_version
         partner {
            id
            name
         }
         can_create_employee_user
         block_not_available_reservation
         include_reservation_in_limit_calculation
         delete_products_schedule_after_period
         return_products_schedule_before_period
         created_at
         image {
            path
            filename
         }
         modules {
            id
            name
         }
         creator {
            id
            name
         }
         addresses {
            type
            description
            address
            city
            zip_code
            province
            note
         }
      }
      modules {
         id
         name
      }
      partners {
         id
         name
      }
   }
`;

export const GET_PARTNERS = gql`
   query getPartners {
      partners {
         id
         name
      }
   }
`;

export const GET_PARTNER = gql`
   query getPartner($id: String!) {
      partner(id: $id) {
         id
         name
         note
         image {
            path
            filename
         }
      }
   }
`;

export const GET_USERS = gql`
   query getUsers($filters: UsersFilterInput) {
      me @client {
         id
         name
         workspace {
            id
            name
         }
      }
      workspaces {
         id
         name
      }
      users(filters: $filters) {
         id
         name
         role
         profile {
            first_name
            last_name
            force_barcode_scanner
         }
         auth {
            username
            verified
            disabled
         }
         workspaces(mine: true) {
            id
            name
            user_modules {
               id
               name
            }
         }
         created_at
      }
   }
`;

export const GET_WORKSPACE_USERS = gql`
   query getUsers($filters: UsersFilterInput) {
      me {
         id
         name
         role
         workspace {
            id
            name
         }
      }
      users(filters: $filters) {
         id
         name
         role
         profile {
            first_name
            last_name
            force_barcode_scanner
         }
         auth {
            username
            verified
            disabled
         }
         workspaces(mine: true) {
            id
            name
            user_modules {
               id
               name
            }
         }
         created_at
      }
   }
`;

export const GET_USER = gql`
   query getUser($id: String!) {
      me {
         id
         all_workspaces: workspaces(all: true) {
            id
            name
            note
            user_modules {
               id
               name
            }
            modules {
               id
               name
            }
         }
      }
      user(id: $id) {
         id
         name
         role
         profile {
            first_name
            last_name
            force_barcode_scanner
         }
         auth {
            username
            phone
            verified
            disabled
         }
         workspaces(mine: true) {
            id
            name
            note
            modules {
               id
               name
            }
            user_modules {
               id
               name
            }
         }
         created_at
      }
   }
`;

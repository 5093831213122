//tipo movimento magazzino (rifornimento, consegna, rientro, prenotazione, utente)
module.exports = {
   APP_NAME: "KEEP TRACK",
   APP_NAME_SLUG: "mercurio",
   USER: {
      ROLES: {
         ADMIN: "ADMIN",
         USER: "USER",
         MANUAL: "MANUAL",
         EMPLOYEE: "EMPLOYEE",
      },
   },
   ATTRIBUTE_FORM_PREFIX: "attributes",
   MAX_UPLOAD_SIZE: 4, //MB
   IMAGE_MIMES: ["image/jpeg", "image/png", "image/gif", "image/webp"],
   IMAGE_CROP_CONFIG: {
      SQUARE: {
         aspect: 1, //width / height
         quality: 0.8,
      },
   },
   VIDEO_MIMES: ["video/mp4"],   
   UPLOAD_SUPPORTED_MIME_TYPES: [['*']],
   DATE_FORMAT: "DD-MM-YYYY",
   DATE_FORMAT_MY: "MM/YYYY",
   DATE_FORMAT_MYD: "MMMM YYYY",
   TIME_FORMAT: "HH:mm",
   DATETIME_FORMAT: "DD/MM/YYYY HH:mm",
   DATETIME_FORMAT_LONG: "DD/MM/YYYY HH:mm:ss",
   PRIMARY_COLOR: "#041529",
   BLUE_COLOR: "#00203a",
   PRIMARY_LIGHT_COLOR: "#295b82",
   DANGER_COLOR: "#cf1322",
   SUCCESS_COLOR: "#13cf62",
   DEFAULT_PAGE_SIZE: 20,
   MAX_DEVICE_DISK_ROW_SLOT: 8,
   MAPBOX: {
      DEFAULT_CENTER: [12.210673, 45.668223],
      ZOOM: 11,
   },
   PRODUCT_RESERVATION_STATE: {
      SELECTED: 1,
      PARTLY_PREPARED: 2,
      PREPARED: 3,
      TO_TAKE: 4,
   },
   RESERVATION_STATE: {
      OPEN: 1,
      PACKED: 2,
   },
   EMPLOYEE_CATEGORY_TYPE: {
      DEFAULT: 1,
      DEPARTMENT: 2,
   },
   DEVICE_MAINTENANCE_MODE: {
      FORCE_IMAGES_DOWNLOAD: 1,
      MARK_EMPTY_SLOTS: 2,
      START_MAINTENANCE: 3,
      ENABLE_OUT_OF_SERVICE: 4,
      DISABLE_OUT_OF_SERVICE: 5,
      RESTART_UI: 6,
      RESTART_DEVICE: 7,
      SCREENSHOT_DEVICE: 8,
      RESET_ZEROTIER: 9,
      UPDATE_SOFTWARE: 10,
   },
   DEVICE_CATEGORY: {
      DISTRIBUTOR: "distributor",
      MEDICAL_BOX: "fak",
      LOCKER: "locker",
      TRACKER: "tracker",
      KIOSK: "kiosk",
      MOBILEDEVICE: "mobile_device",
      LOCK_KEY_DISTRIBUTOR: "lock_key_distributor",
   },
   DEVICE_SLOT_STATE: {
      EMPTY: "empty",
      FULL: "full",
      UNCOMMIT: "uncommit",
      TO_REFILL: "to_empty",
   },
   MODULES: {
      WORKWEAR: "workwear",
      DPI: "dpi",
      FIRSTAIDKIT: "firstaidkit",
      CONSUMABLES: "consumables",
      EQUIPMENT: "equipment",
      GUESTS: "guests",
      COMMUNICATIONS: "communications",
      PICKUP: "pickup",
      KEYS: "keys",
   },
   INVENTORY_LOG_TYPES: {
      CORRECTION: {
         text: "inventory_correction",
         color: "red",
         value: "CORRECTION",
      },
      COMMITTED: {
         text: "inventory_committed",
         color: "warning",
         value: "COMMITTED",
      },
      UNCOMMITTED: {
         text: "inventory_uncommitted",
         color: "green",
         value: "UNCOMMITTED",
      },
      LOAD: {
         text: "inventory_load",
         color: "green",
         value: "LOAD",
      },
      UNLOAD: {
         text: "inventory_unload",
         color: "red",
         value: "UNLOAD",
      },
      EMPLOYEE_UNLOAD: {
         text: "employee_inventory_unload",
         color: "red",
         value: "EMPLOYEE_UNLOAD",
      },
      EMPLOYEE_LOAD_BACK: {
         text: "employee_inventory_load",
         color: "green",
         value: "EMPLOYEE_LOAD_BACK",
      },
   },
   SURVEY_ANSWER_TYPES: {
      NUMBER: "NUMBER",
      SELECT: "SELECT",
      TEXT: "TEXT",
      DATE: "DATE",
   },
   LAYOUT_BREAKPOINT: {
      XS: 0,
      SM: 576,
      MD: 768,
      LG: 992,
      XL: 1200,
      XXL: 1600,
   },
};

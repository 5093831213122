import React from "react";

const UserInfo = ({ partner }) => {
   return partner.image ? (
      <img className="partner-logo" alt={partner.name} src={`${process.env.REACT_APP_STATIC_URL}/${partner.image.path}`} />
   ) : (
      <span className="gx-d-inline-block gx-text-white">{partner.name}</span>
   );
};

export default UserInfo;

import React from "react";
import { Spin } from "antd";

const FullPageLoader = () => (
   <div className="gx-loader-container">
      <Spin size="large" />
   </div>
);

export default FullPageLoader;

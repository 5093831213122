import { defaultTheme } from "@ant-design/compatible";
import { PRIMARY_COLOR } from "./constants";

export const getCompatibleTheme = () => {
   return {
      algorithm: defaultTheme.algorithm,
      components: {
         Menu: {
            activeBarWidth: 3,
            itemBorderRadius: 0,
            itemHoverBg: "transparent",
            itemBg: "#f4f9ff",
            itemHoverColor: "#f87f33",
            itemMarginInline: 0,
            itemSelectedBg: "#e4ebf3",
            itemSelectedColor: "#f87f33",
            subMenuItemBorderRadius: 0,
            itemHeight: 34,
         },
      },
      token: {
         colorPrimary: PRIMARY_COLOR,
         colorLink: PRIMARY_COLOR,
         colorBgContainer: "#fff",
         borderRadius: 4,
         wireframe: true,
      },
   };
};

import { Popover, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { logout, isAdmin, isEmployee } from "../../utils/auth";
import { useApolloClient } from "@apollo/client";
import UserAvatar from "./UserAvatar";

const UserInfo = ({ user }) => {
   const { t } = useTranslation();

   const client = useApolloClient();
   const history = useHistory();

   if (!user) return <Spin size="small" />;   

   const userMenuOptions = (
      <ul className="gx-user-popover">
         {!isEmployee(user) && (
            <li>
               <Link className="gx-d-block" to="/admin/users/settings">
                  {t("settings")}
               </Link>
            </li>
         )}
         <li>
            <Link
               className="gx-d-block"
               to="/login"
               onClick={(e) => {
                  e.preventDefault();
                  logout();
                  client.resetStore();
                  history.replace("/login");
               }}
            >
               {t("logout")}
            </Link>
         </li>
      </ul>
   );

   return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
         <UserAvatar className="gx-pointer empty-avatar" user={user} />
         <span className="gx-d-inline-block gx-pointer gx-text-white gx-ml-2" style={isAdmin(user) ? { color: "red" } : {}}>
            {user.profile.first_name}
         </span>
      </Popover>
   );
};

export default UserInfo;

import { GET_SERVER_ERRORS } from "../../graphql/local/query";
import { startsWith } from "lodash";

export const defaultErrorsHandler = ({ errors }, client) => {
   let hasErrors = errors && errors.length > 0;
   if (hasErrors) {
      const serverErrors = errors.map((e) => {
         return {
            message: !startsWith(e.message, 'server_error.') ? `server_error.${e.message}` : e.message,
            path: e.path,
         };
      });
      client.writeQuery({
         query: GET_SERVER_ERRORS,
         data: { serverErrors },
      });
   }
   return hasErrors;
};

export const defaultExceptionHandler = ({ ex }, client) => {
   client.writeQuery({
      query: GET_SERVER_ERRORS,
      data: { serverErrors: ex.graphQLErrors },
   });
};

export const getFirstErrorMessage = (errors) => {
   let firstMessage = errors && errors.length > 0 ? errors[0]?.message : null;

   if (firstMessage) {
      if (firstMessage.indexOf("Error:") > -1) {
         firstMessage = firstMessage.replace("Error:", "");
      }
      firstMessage = firstMessage.trim();
   }
   return firstMessage;
};

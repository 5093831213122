import { GET_SERVER_ERRORS } from "../../graphql/local/query";
import { message } from "antd";

export const defaultSuccessHandler = ({ t = null, text = null }, client) => {
   text = text ? text : t("default_form_success_message");

   message.success({ content: text });

   client.writeQuery({
      query: GET_SERVER_ERRORS,
      data: { serverErrors: [] },
   });
};

import { gql } from "@apollo/client";

export const LOGIN = gql`
   mutation getLogin($username: String!, $password: String!) {
      login(username: $username, password: $password) {
         token
      }
   }
`;

export const FORGOT_PASSWORD = gql`
   mutation forgotPassword($username: String!) {
      forgotPassword(username: $username)
   }
`

export const RESET_PASSWORD = gql`
   mutation resetPassword($resetToken: String!, $password: String!) {
      resetPassword(resetToken: $resetToken, password: $password)
   }
`;

export const UPDATE_USER_SETTINGS = gql`
   mutation updateSettings($user: UpdateUserInput!) {
      updateAccount(user: $user)
   }
`;

export const CHANGE_WORKSPACE = gql`
   mutation changeWorkspace($id: String!) {
      changeWorkspace(id: $id) {
         token
      }
   }
`;

export const CHANGE_MODULE = gql`
   mutation changeModule($id: String!) {
      changeModule(id: $id)
   }
`;

export const CREATE_WORKSPACE = gql`
   mutation createWorkspace($workspace: CreateWorkspaceInput!, $user: String) {
      createWorkspace(workspace: $workspace, user: $user)
   }
`;

export const UPDATE_WORKSPACE = gql`
   mutation updateWorkspace($id: String!, $workspace: CreateWorkspaceInput!) {
      updateWorkspace(id: $id, workspace: $workspace)
   }
`;

export const DELETE_WORKSPACE = gql`
   mutation deleteWorkspace($id: String!) {
      deleteWorkspace(id: $id)
   }
`;

export const DELETE_WORKSPACE_INVENTORY = gql`
   mutation deleteWorkspaceInventory($workspace_id: String!) {
      deleteWorkspaceInventory(workspace_id: $workspace_id)
   }
`;

export const DELETE_WORKSPACE_FAK_INVENTORY = gql`
   mutation deleteFAKInventoryLogs($workspace_id: String!) {
      deleteFAKInventoryLogs(workspace_id: $workspace_id)
   }
`;

export const CREATE_USER = gql`
   mutation createAccount($user: CreateUserInput!) {
      createAccount(user: $user)
   }
`;

export const CREATE_INTERNAL_USER = gql`
   mutation createInternalAccount($user: CreateInternalUserInput!, $workspaces_id: [String]!) {
      createInternalAccount(user: $user, workspaces_id: $workspaces_id)
   }
`;

export const UPDATE_USER = gql`
   mutation updateUser($id: String!, $user: AdminUpdateUserInput!, $workspaces_id: [String]) {
      updateAccountById(id: $id, user: $user, workspaces_id: $workspaces_id)
   }
`;

export const DELETE_USER = gql`
   mutation deleteUser($id: String!) {
      deleteAccount(id: $id)
   }
`;

export const CREATE_PARTNER = gql`
   mutation createWorkspace($partner: CreatePartnerInput!) {
      createPartner(partner: $partner)
   }
`;

export const UPDATE_PARTNER = gql`
   mutation updatePartner($id: String!, $partner: CreatePartnerInput!) {
      updatePartner(id: $id, partner: $partner)
   }
`;

export const DELETE_PARTNER = gql`
   mutation deletePartner($id: String!) {
      deletePartner(id: $id)
   }
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
   mutation sendResetPasswordEmail($id: String!) {
      sendResetPasswordEmail(id: $id)
   }
`;
export const SEND_WELCOME_PASSWORD_EMAIL = gql`
   mutation sendWelcomePasswordEmail($id: String!) {
      sendWelcomePasswordEmail(id: $id)
   }
`;

export const getFormStyle = (span, center = false) => {
   const wrapperCol = center ? 24 - span * 2 : 16;

   let layout = {
      labelCol: {
         span: span,
      },
      wrapperCol: {
         span: wrapperCol,
      },
   };
   let tailLayout = {
      wrapperCol: {
         offset: span,
         span: wrapperCol,
      },
   };

   return { layout, tailLayout };
};

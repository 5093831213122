import { Button, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { getFormStyle } from "../components/Form/formLayout";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../graphql/user/mutation";
import ServerErrors from "../components/Errors/ServerErrors";
import { defaultExceptionHandler, getFirstErrorMessage } from "../utils/form/errors";
import { isLoggedIn } from "../utils/auth";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import logo from "../assets/logo_light.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const { layout, tailLayout } = getFormStyle(8);

const FormItem = Form.Item;

const ForgotPassword = (props) => {
   const { t, i18n } = props;

   const history = useHistory();

   const [forgotPassword, { loading, client }] = useMutation(FORGOT_PASSWORD);

   const handleSubmit = (values) => {
      forgotPassword({ variables: values })
         .then((res) => {
            const successResponse = get(res, "data.forgotPassword", null);
            const errorMessage = getFirstErrorMessage(res.errors);

            if (successResponse) {               
               message.success({ content: t("reset_password_email_sent") });
            } else if (errorMessage && i18n.exists(errorMessage)) {
               message.error({ content: t(errorMessage) });
            } else {
               message.error({ content: t("default_form_error_message") });
            }
         })
         .catch((ex) => defaultExceptionHandler({ ex }, client));
   };

   useEffect(() => {
      if (isLoggedIn()) {
         history.push("/admin");
      }
   }, [history]);

   return (
      <div className="gx-app-login-wrap">
         <div className="gx-app-login-container">
            <ServerErrors className="gx-mb-2" />
            <div className="gx-app-login-main-content" style={{ minHeight: 333 }}>
               <div className="gx-app-logo-content">
                  <div className="gx-app-logo-content-bg">
                     <img className="gx-d-none gx-d-sm-block" alt="KEEPTRACK" style={{ width: 50 % "" }} src={logo} />
                  </div>                  
               </div>
               <div className="gx-app-login-content">
                  <Form {...layout} onFinish={handleSubmit} onFinishFailed={() => { }} className="gx-signin-form gx-form-row0">
                     <FormItem
                        label={t("email")}
                        name="username"
                        rules={[
                           {
                              required: true,
                              message: "Inserisci il tuo indirizzo email",
                           },
                           {
                              type: "email",
                              message: "Inserisci un indirizzo email valido",
                           },
                        ]}
                     >
                        <Input placeholder="Email" />
                     </FormItem>                     

                     <FormItem {...tailLayout}>
                        <Button type="primary" className="gx-mb-0" htmlType="submit">
                           {loading ? <LoadingOutlined /> : null}
                           {t("send_reset_password_email")}
                        </Button>
                     </FormItem>
                  </Form>


                  <Link style={{ marginTop: 150 }} to="/login" className="gx-text-primary gx-fs-md gx-mb-0 gx-text-right gx-d-block">
                     {t("back_to_login")}
                  </Link>
               </div>
            </div>
            <div className="gx-mt-3 gx-align-center">
               <center>
                  <a className="gx-link  gx-text-white no-underline" href="https://keeptrack.it" target="_blank" rel="noopener noreferrer">
                     KeepTrack
                  </a>
               </center>
            </div>
         </div>
      </div>
   );
};

export default withTranslation()(ForgotPassword);

import { Col, Card, Typography, message, QRCode, Popover } from "antd";
import React from "react";
import MRow from "../components/Layout/MRow";
import { withTranslation } from "react-i18next";
import { BLUE_COLOR } from "../config/constants";
import { isEmployee } from "../utils/auth";

const { Title, Text } = Typography;

const canAccessModule = (me, module) => {
   const wsModuleIds = me.workspace.modules.map((m) => m.id);
   return (!isEmployee(me) && wsModuleIds.includes(module.id)) ||
      (isEmployee(me) && wsModuleIds.includes(module.id) && module.slug === 'keys')
};

const ModuleSelectView = ({ me, modules, t, onChangeModule }) => {
   return (
      <>
         <MRow>
            <Col span={6} xs={24} md={12} lg={12}>
               <Typography>
                  <Title level={3}>{t("intro_greetings_title", { name: me.profile.first_name })}</Title>
                  <Text strong>{t("intro_greetings_subtitle").toUpperCase()}</Text>
               </Typography>
            </Col>
            <Col span={6} xs={24} md={12} lg={12} className="gx-header-notifications gx-mr-0" style={{ justifyContent: "flex-end" }}>
               <div>
                  {t("install_app_on_tablet")}
                  <div>
                     <Popover
                        content={
                           <center>
                              <a href="https://play.google.com/store/apps/details?id=it.keeptrack.main" target="_blank" rel="noopener noreferrer">
                                 <QRCode bordered={false} value="https://play.google.com/store/apps/details?id=it.keeptrack.main" />
                              </a>
                              <Text type="secondary"> vai al Play Store</Text>
                           </center>
                        }
                     >
                        <img alt="Google Play Store" style={{ height: "60px" }} src={require("../assets/google-play-badge.png")} />
                     </Popover>
                     <Popover
                        content={
                           <center>
                              <a
                                 href="https://apps.apple.com/it/app/keeptrack/id1671034139?itsct=apps_box_link&itscg=30200"
                                 target="_blank"
                                 rel="noopener noreferrer"
                              >
                                 <QRCode bordered={false} value="https://apps.apple.com/it/app/keeptrack/id1671034139?itsct=apps_box_link&itscg=30200" />

                                 <Text type="secondary"> vai all'App Store</Text>
                              </a>
                           </center>
                        }
                     >
                        <img alt="Apple App Store" style={{ height: "60px" }} src={require("../assets/appstore-badge.png")} />
                     </Popover>
                  </div>
               </div>
            </Col>
         </MRow>
         <MRow>
            {modules
               .filter((m) => {
                  if (me.workspace.lite_version) {
                     return m.lite_version_visible;
                  } else {
                     return true;
                  }
               })
               .map((module) => {
                  
                  const included = canAccessModule(me, module);

                  return (
                     <Col
                        key={module.id}
                        span={6}
                        xs={24}
                        md={12}
                        lg={12}
                        xl={8}
                        style={{ display: "flex" }}
                        onClick={() => {
                           if (included) {
                              onChangeModule(module);
                           } else {
                              message.error(t("module_inactive"));
                           }
                        }}
                     >
                        <Card
                           style={{
                              flex: 1,
                              cursor: "pointer",
                              opacity: included ? 1.0 : 0.3,
                              backgroundColor: BLUE_COLOR,
                              backgroundImage: `url(${require("../assets/bgcover_" + module.slug + ".jpeg")})`,

                              backgroundSize: "cover",
                              backgroundPosition: "center bottom",
                              color: "white",
                              textShadow: "0 0 10px rgba(0 0 0 / 85%)",
                              display: "flex",
                              paddingTop: 100,
                           }}
                           bodyStyle={{
                              background: "linear-gradient(transparent, #001323ee, #001323ff)",
                              marginTop: "auto",
                           }}
                        >
                           <Title style={{ color: "white", marginBottom: 0, fontSize: "20pt" }} level={1}>
                              {t("module_title_" + module.slug)}
                           </Title>
                           <Text
                              style={{
                                 color: "#cfcfcf",
                                 lineHeight: "1em",
                                 fontSize: "12pt",
                              }}
                           >
                              {t("module_description_" + module.slug)}
                           </Text>
                        </Card>
                     </Col>
                  );
               })}
         </MRow>
      </>
   );
};

export default withTranslation()(ModuleSelectView);

export const hasPersistence = () => !!window.localStorage;

export const persist = (key, data) => {
   localStorage.setItem(key, JSON.stringify(data));
};

export const getSession = (key) => {
   try {
      return JSON.parse(localStorage.getItem(key));
   } catch (e) {
      console.log("persistance error", e);
      return {};
   }
};

import { ApolloClient, from, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import { GET_SERVER_ERRORS } from "./local/query";
import { getAuthToken } from "../utils/auth";
import { onError } from "@apollo/link-error";

const cache = new InMemoryCache();

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_GATEWAY_URL,
});

const authLink = setContext((_, { headers }) => {
   const token = getAuthToken();

   return {
      headers: {
         ...headers,
         authorization: token ? `Bearer ${token}` : "",
      },
   };
});

const errorLink = onError(() => {
   //handle global exception here
});

const client = new ApolloClient({
   cache: cache,
   link: from([authLink, errorLink, httpLink]),
   defaultOptions: {
      watchQuery: {
         fetchPolicy: "cache-and-network",
         errorPolicy: "ignore",
      },
      query: {
         fetchPolicy: "network-only",
         errorPolicy: "ignore",
      },
      mutate: {
         errorPolicy: "all",
      },
   },
});

function writeInitialData() {
   cache.writeQuery({
      query: GET_SERVER_ERRORS,
      data: {
         serverErrors: [],
      },
   });
}

writeInitialData();

client.onResetStore(writeInitialData);

export default client;

import { Layout, Space } from "antd";
import React from "react";
import UserInfo from "../User/UserInfo";
import PartnerInfo from "../User/PartnerInfo";
import { get } from "lodash";
import { Link } from "react-router-dom";

const Header = Layout.Header;

/**
 * @deprecated
 * @param {*} param0
 * @returns
 */

const Workspace = ({ me }) => {
   const getRealUser = () => {
      const notMyWorkspace = me.workspace.owners && !me.workspace.owners.map((ow) => ow.id).includes(me.id);
      if (notMyWorkspace) {
         return (
            <>
               <br />
               <small className="gx-pl-2 gx-mt-2 gx-d-inline-block">{get(me, "workspace.owners.0.name", null)}</small>
            </>
         );
      }
   };

   const WorkspaceComponent = (
      <Space>
         {me.workspace.image ? (
            <img className="workspace-logo" alt={me.workspace.name} src={`${process.env.REACT_APP_STATIC_URL}/${me.workspace.image.path}`} />
         ) : (
            <span className="gx-pl-2 gx-language-name">{get(me, "workspace.name", "-")}</span>
         )}

         {getRealUser()}
      </Space>
   );

   if (me.workspaces.length > 1) {
      return (
         <Link to="/admin/workspaces/home" className="gx-flex-row gx-align-items-center gx-text-white">
            {WorkspaceComponent}
         </Link>
      );
   } else {
      return <span className="gx-pointer gx-flex-row gx-align-items-center gx-text-white">{WorkspaceComponent}</span>;
   }
};

const PrivateHeader = ({ me, t, onChangeModule, hideWorkspace = false }) => {
   return (
      <Header className="site-layout-background" style={{ padding: 0 }}>
         {!me.module ? (
            <img
               className="logo gx-pointer"
               onClick={() => {
                  onChangeModule({ name: "", id: "" });
               }}
               src={require("../../assets/logo_light.svg")}
               alt={process.env.REACT_APP_SITE_TITLE}
            />
         ) : (
            <img
               className="logo gx-pointer"
               src={require("../../assets/logo_light_" + me.module.slug + ".svg")}
               onClick={() => {
                  onChangeModule({ name: "", id: "" });
               }}
               alt={process.env.REACT_APP_SITE_TITLE}
            />
         )}
         {me.workspace.partner && (
            <div className="gx-user-nav gx-header-notifications gx-ml-5 gx-pl-2">
               <span className="gx-mb-1 gx-mr-2" style={{ fontSize: "12px", color: "#aaa" }}>
                  {t("in_collaboration_with")}
               </span>
               <PartnerInfo partner={me.workspace.partner} />
            </div>
         )}
         <ul className="gx-header-notifications gx-ml-auto gx-mr-3">
            {!hideWorkspace && (
               <li>
                  <Workspace me={me} t={t} />
               </li>
            )}
            <li className="gx-user-nav">
               <UserInfo user={me} />
            </li>
         </ul>
      </Header>
   );
};

export default PrivateHeader;

import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Alert } from "antd";
import { GET_SERVER_ERRORS } from "../../graphql/local/query";
import { useTranslation } from "react-i18next";

const cleanMessage = (msg) => {
   if (msg) {
      if (msg.indexOf("Error:") > -1) {
         msg = msg.replace("Error:", "");
      }
      msg = msg.trim();
   }
   return msg;
};

const ErrorsList = ({ errors }) => {
   const { t } = useTranslation();

   return (
      <ul className="ant-list">
         {errors.map((e, i) => {
            return (
               <li className="ant-list-item" key={`e${i}`}>
                  {t(cleanMessage(e.message))}
               </li>
            );
         })}
      </ul>
   );
};

const ServerErrorsAlert = ({ errors, client, className = "", expirationTime = null }) => {
   const onCleanErrors = () => {
      client.writeQuery({
         query: GET_SERVER_ERRORS,
         data: { serverErrors: [] },
      });
   };

   useEffect(() => {
      if (expirationTime) {
         setTimeout(() => {
            onCleanErrors();
         }, expirationTime);
      }
   }, [expirationTime]);

   return (
      <Alert
         onClose={() => {
            onCleanErrors();
         }}
         className={className}
         closable={true}
         type="error"
         message={<ErrorsList errors={errors} />}
      />
   );
};

const ServerErrors = ({ className = "", expirationTime = null }) => {
   const { data, loading, client } = useQuery(GET_SERVER_ERRORS);

   if (loading || !data || !data.serverErrors || data.serverErrors.length === 0) {
      return null;
   }

   return <ServerErrorsAlert errors={data.serverErrors} className={className} client={client} expirationTime={expirationTime} />;
};

export default ServerErrors;

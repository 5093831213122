import React, { lazy, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import NoMatch from "./NoMatch";
import FullPageLoader from "../components/Layout/FullPageLoader";
import { USER } from "../config/constants";
import PermittedRoute from "../components/Router/PermittedRoute";
import ModuleSelectView from "./ModuleSelectView";

const AdminRoutes = ({ me, modules, match, onChangeModule, location }) => {
   function WaitingComponent(Component) {
      const WrappedComponent = (props) => (
         <Suspense fallback={<FullPageLoader />}>
            <Component {...props} />
         </Suspense>
      );
      return WrappedComponent;
   }

   const allowedUrl = [
      `${match.url}/platform_news`,
      `${match.url}/documentation`,
      `${match.url}/userguide`,
      `${match.url}/assistance`,
      `${match.url}/users/settings`,
   ];

   const workspaceSelectionUrl = ["/admin/workspaces/home"];

   if (!allowedUrl.includes(location.pathname)) {
      if (!me.module && !workspaceSelectionUrl.includes(location.pathname)) {
         return <ModuleSelectView me={me} modules={modules} onChangeModule={onChangeModule} />;
      }
   }

   return (
      <Switch>
         <Route exact={true} path={`${match.url}`} component={WaitingComponent(lazy(() => import("./Dashboard")))} />
         <Route path={`${match.url}/platform_news`} component={WaitingComponent(lazy(() => import("./Other/Platform_news")))} />
         <Route path={`${match.url}/documentation`} component={WaitingComponent(lazy(() => import("./Other/Documentation")))} />
         <Route path={`${match.url}/userguide`} component={WaitingComponent(lazy(() => import("./Other/UserGuide")))} />
         <Route path={`${match.url}/assistance`} component={WaitingComponent(lazy(() => import("./Other/Assistance")))} />
         <Route path={`${match.url}/statistics`} component={WaitingComponent(lazy(() => import("./Statistics")))} />
         <Route path={`${match.url}/catalog`} component={WaitingComponent(lazy(() => import("./Catalog")))} />
         <Route path={`${match.url}/demo`} component={lazy(() => import("./Demo"))} />
         <Route path={`${match.url}/employees`} component={WaitingComponent(lazy(() => import("./Employee")))} />
         <Route path={`${match.url}/devices`} component={WaitingComponent(lazy(() => import("./Device")))} />
         <Route path={`${match.url}/warehouse`} component={WaitingComponent(lazy(() => import("./Warehouse")))} />
         <Route path={`${match.url}/partners`} component={lazy(() => import("./Partners"))} />
         <Route path={`${match.url}/workspaces`} component={lazy(() => import("./Workspace"))} />
         <Route path={`${match.url}/users`} component={lazy(() => import("./User"))} />
         <Route path={`${match.url}/user-manager`} component={lazy(() => import("./UserManager"))} />
         <Route path={`${match.url}/reports`} component={lazy(() => import("./Report/Table"))} />
         <Route path={`${match.url}/notifications`} component={lazy(() => import("./Notification"))} />
         <Route path={`${match.url}/delivery/manual`} component={lazy(() => import("./Delivery/Manual"))} />
         <Route path={`${match.url}/surveys`} component={lazy(() => import("./Survey"))} />
         <Route path={`${match.url}/scheduled-reservations`} component={lazy(() => import("./ScheduledReservation"))} />

         <PermittedRoute
            role={USER.ROLES.ADMIN}
            exact={true}
            path={`${match.url}/workspaces-dashboard`}
            component={lazy(() => import("./WorkspacesDashboard"))}
         />
         <Route exact={true} path={`${match.url}/diagnostic`} component={lazy(() => import("./Diagnostic"))} />
         <Route path="*" component={NoMatch} />
      </Switch>
   );
};

export default withRouter(AdminRoutes);

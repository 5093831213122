import React, { Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import Admin from "./Admin";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import client from "../../graphql/client";
import { ConfigProvider } from "antd";
import Login from "../Login";
import PermittedRoute from "../../components/Router/PermittedRoute";
import { USER } from "../../config/constants";
import FullPageLoader from "../../components/Layout/FullPageLoader";
import itIT from "antd/es/locale/it_IT";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { getCompatibleTheme } from "../../config/theme";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";

//non funziona
dayjs.locale("it");

const App = () => {
   return (
      <ApolloProvider client={client}>
         <ConfigProvider locale={itIT} theme={getCompatibleTheme()}>
            <Suspense fallback={<FullPageLoader />}>
               <Router>
                  <Switch>
                     <Route exact path={["/", "/login"]} component={Login} />
                     <Route exact path="/forgot-password" component={ForgotPassword} />
                     <Route path="/reset-password/:token" component={ResetPassword} />
                     <PermittedRoute role={USER.ROLES.EMPLOYEE} path="/admin" component={Admin} />
                  </Switch>
               </Router>
            </Suspense>
         </ConfigProvider>
      </ApolloProvider>
   );
};

export default App;

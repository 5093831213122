import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Layout, Result, message, Space, Typography } from "antd";
import MainMenu from "../../components/Menu";
import Header from "../../components/Layout/Header";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INIT_DATA } from "../../graphql/user/query";
import { Redirect, Link, useHistory, useLocation } from "react-router-dom";
import FullPageLoader from "../../components/Layout/FullPageLoader";
import { logout } from "../../utils/auth";
import AdminRoutes from "../../routes";
import { USER } from "../../config/constants";
import { CHANGE_MODULE } from "../../graphql/user/mutation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { first, includes } from "lodash";
import { GET_SERVER_ERRORS } from "../../graphql/local/query";
const { Sider, Content, Footer } = Layout;

const SIDEBAR_HIDDEN_PATH = ["/admin/workspaces/home"];
// const LAYOUT_ALT_PATH = ["/admin/workspaces/home"];
const LAYOUT_ALT_PATH = [];
const WORKSPACE_MENU_HIDDEN_PATH = ["/admin/workspaces/home"];

const Admin = (props) => {
   const history = useHistory();

   const routerLocation = useLocation();

   const { t, i18n } = props;
   const { data, error, loading, refetch, client } = useQuery(GET_INIT_DATA);

   const [changeModule, { loading: changeModuleLoading }] = useMutation(CHANGE_MODULE);

   useEffect(() => {
      //clean server errors on route change
      client.writeQuery({
         query: GET_SERVER_ERRORS,
         data: { serverErrors: [] },
      });
   }, [routerLocation, client]);

   if (loading) return <FullPageLoader />;
   //TODO move to content
   if (error)
      return (
         <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
               <Link to="/login" type="primary">
                  Back Home
               </Link>
            }
         />
      );

   const { me, modules } = data;

   if (!me) {
      logout();
      return <Redirect to="/login" />;
   }

   if (me.role === USER.ROLES.MANUAL && props.location.pathname !== "/admin/delivery/manual" && props.location.pathname !== "/admin/users/settings") {
      return <Redirect to="/admin/delivery/manual" />;
   }

   const onChangeModule = (_module) => {
      changeModule({ variables: { id: _module.id } }).then(({ errors }) => {
         if (!errors) {
            refetch();
            history.push("/admin");
         } else if (i18n.exists(first(errors)?.message)) {
            message.error(t(errors[0].message));
         } else {
            message.error(t("generic_error"));
         }
      });
   };

   const getSiderProps = () => {
      const isSidebarHidden = SIDEBAR_HIDDEN_PATH.includes(history.location.pathname) || !me.module;
      if (isSidebarHidden) {
         return {
            breakpoint: "lg",
            width: 0,
            zeroWidthTriggerStyle: { display: "none" },
            collapsedWidth: "0",
         };
      } else {
         return {
            breakpoint: "lg",
            width: 240,
            collapsedWidth: "0",
         };
      }
   };

   const getLayoutProps = () => {
      if (LAYOUT_ALT_PATH.includes(history.location.pathname)) {
         return {
            className: "gx-layout-content gx-bg-primary-light",
         };
      } else {
         return {
            className: "gx-layout-content",
         };
      }
   };

   return (
      <Layout>
         <Header
            changeModuleLoading={changeModuleLoading}
            me={me}
            refetchInitData={refetch}
            t={t}
            onChangeModule={onChangeModule}
            hideWorkspace={WORKSPACE_MENU_HIDDEN_PATH.includes(history.location.pathname)}
         />
         <Layout {...getLayoutProps()}>
            <Sider {...getSiderProps()}>
               <MainMenu me={me} />
            </Sider>
            <Content
               className="site-layout-background"
               style={{
                  margin: "0px 0px",
                  padding: 24,
                  minHeight: 280,
               }}
            >
               <AdminRoutes {...props} me={me} modules={modules} onChangeModule={onChangeModule} />
            </Content>
         </Layout>
         <Footer className="site-layout-footer">
            {!me.module || includes(["firstaidkit", "keys"], me.module.slug) ? null : (
               <Space
                  size="large"
                  style={{
                     float: "left",
                     paddingLeft: 16,
                     height: "100%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <Link to="/admin/delivery/manual">
                     <Typography.Text className="bottom-menu main">
                        <FontAwesomeIcon transform="left-6" icon={duotone("person-carry-box")} />
                        {t("manual_consign")}
                     </Typography.Text>
                  </Link>
               </Space>
            )}

            <Space
               size="large"
               style={{
                  float: "right",
                  paddingRight: 16,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <Link to="/admin/platform_news">
                  <Typography.Text className="bottom-menu">
                     <FontAwesomeIcon transform="left-6" icon={duotone("megaphone")} />
                     {t("news")}
                  </Typography.Text>
               </Link>
               <Link to="/admin/documentation">
                  <Typography.Text className="bottom-menu">
                     <FontAwesomeIcon transform="left-6" icon={duotone("cloud-arrow-down")} />
                     {t("documentation")}
                  </Typography.Text>
               </Link>
               <Link to="/admin/userguide">
                  <Typography.Text className="bottom-menu">
                     <FontAwesomeIcon transform="left-6" icon={duotone("books")} />
                     {t("user_guide")}
                  </Typography.Text>
               </Link>
               <Link to="/admin/assistance">
                  <Typography.Text className="bottom-menu">
                     <FontAwesomeIcon transform="left-6" icon={duotone("circle-question")} />
                     {t("assistance")}
                  </Typography.Text>
               </Link>
            </Space>
         </Footer>
      </Layout>
   );
};

export default withTranslation()(Admin);

import { Avatar } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

const UserAvatar = ({ user, className = "" }) => {
   if (user.image) {
      return <Avatar className={className} shape="round" size="middle" src={`${process.env.REACT_APP_STATIC_URL}/${user.image.path}`} />;
   } else {
      return <Avatar className={className} shape="round" size="middle" icon={<FontAwesomeIcon icon={duotone("user")} />} />;
   }
};

export default UserAvatar;

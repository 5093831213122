import { hasPersistence } from "./session";
import { APP_NAME_SLUG } from "../config/constants";
import { startsWith } from "lodash";

const NAMESPACE = `${APP_NAME_SLUG}:auth`;
const AUTH_TOKEN_KEY = `${NAMESPACE}:token`;

export const isLoggedIn = () => {
   const isLogged = !!getAuthToken(AUTH_TOKEN_KEY);
   return isLogged;
};

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

export const logout = () => {
   localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const cleanUISettings = () => {
   Object.keys(localStorage).forEach((key) => {
      ["product", "employee"].map((section) => {
         if (startsWith(key, section)) {
            localStorage.removeItem(key);
         }
         return null;
      });
   });
};

export const storeAuthToken = ({ token }) => {
   if (!hasPersistence()) {
      throw new Error("Local storage not supported");
   }

   cleanUISettings();

   localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const isAdmin = (user) => {
   return user && user.role === "ADMIN";
};

export const isUser = (user) => {
   return user && user.role === "USER";
};

export const isEmployee = (user) => {
   return user && user.role === "EMPLOYEE";
}

export const isManual = (user) => {
   return user && user.role === "MANUAL";
};

import React from "react";
import { Row } from "antd";

/**
 * gx-mt-4 per compatibilità con il layout di antd 5
 */
const MRow = ({ children, className = "gx-mt-4" }) => {
   return (
      <Row gutter={[16, 16]} className={className}>
         {children}
      </Row>
   );
};

export default MRow;

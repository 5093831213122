/* eslint-disable */
// import "./wdyr"; // <--- first import
import "./styles/mercurio.less";
import React from "react";

// import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import "./config/i18n";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(duration)
dayjs.extend(relativeTime)

Sentry.init({
   dsn: "https://8f0909d352b6491ea0f60ec3f9df504f@o563187.ingest.sentry.io/5963942",
   integrations: [new Integrations.BrowserTracing()],
   tracesSampleRate: 0.1,
   environment: process.env.REACT_APP_ENV,
   enabled: process.env.REACT_APP_ENABLE_SENTRY === "true",
});

ReactDOM.render(<App />, document.getElementById("root"));

// const container = document.getElementById("root");
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
//    // <StrictMode>
//    <App />
//    // </StrictMode>
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

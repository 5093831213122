import React from "react";
import { Route } from "react-router-dom";
import { USER } from "../../config/constants";
import { Redirect } from "react-router-dom";
import { isLoggedIn, isAdmin, isEmployee } from "../../utils/auth";
import { GET_LOCAL_USER } from "../../graphql/local/query";
import { useQuery } from "@apollo/client";

const PermittedRoute = ({ role, ...rest }) => {
   const { data, error, loading } = useQuery(GET_LOCAL_USER);

   if (loading || error) return null;

   if (role === USER.ROLES.ADMIN && !isAdmin(data?.me)) {
      return <Redirect to="/" />;
   }

   if (role === USER.ROLES.USER && isEmployee(data?.me)) {
      return <Redirect to="/" />;
   }

   if(!isLoggedIn()){
      return <Redirect to="/login" />
   }

   return <Route {...rest} />;
};

export default PermittedRoute;
